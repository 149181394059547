import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {Helmet} from "react-helmet";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import WhatsApp from "../images/whatsapp.png";
import "../assets/css/style.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import $ from "jquery";

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

// markup
const WhatsappPage = () => {

  const data = useStaticQuery(graphql`
        query WhatsappPage {
            wpProject {
                projectMeta {
                  projectId
                  project
                }
            }
            wpContact {
                contactMeta {
                  primaryNumber
                  secondaryNumber
                  primaryWhatsapp
                  secondaryWhatsapp
                }
            }
        }
    `)
  
  const projectid = data.wpProject.projectMeta.projectId
  const project = data.wpProject.projectMeta.project
  const date = new Date();
  const time = date.getHours();
  let contact_number;
  if(time < 19){
      contact_number = data.wpContact.contactMeta.primaryNumber
  }else{
      contact_number = data.wpContact.contactMeta.secondaryNumber
  }

  let whatsapp_number;
  if(time < 19){
      whatsapp_number = data.wpContact.contactMeta.primaryWhatsapp
  }else{
      whatsapp_number = data.wpContact.contactMeta.secondaryWhatsapp
  }

const fnSendMeDetail = () => {
  const url = document.location.href;
  let source = "BLANKW";
  if(url.indexOf("?") != -1){
      const para = url.split("?")[1];
      let sources;
      para.split("&").forEach(function(item) {
      if(item.indexOf("utm_source") != -1){
              sources = item.split("=")[1];
          }   
      });
      if(sources == "GoogleSearch")
      source = "BLANKW";
      else if(sources == "GoogleDisplay" || sources == "GoogleDiscovery")
      source = "BLANKD";
      else if(sources == "SMS")
      source = "ASY";
      else if(sources == "Facebook")
      source = "AFY";
      else if(sources == "Instagram")
      source = "ING";
  }

    const mobile = document.querySelector('input').value

    if (mobile.split(" ")[1] == undefined) {
        document.querySelector('.whatsappErrorMsg').style.display = 'block'
        document.querySelector('.whatsappErrorMsg').innerHTML = 'Please Enter Mobile No.'
        return false
    } else {
        document.querySelector('.whatsappErrorMsg').style.display = 'none'
        document.querySelector('.whatsappErrorMsg').innerHTML = ''
    }

    var lead = {
        mobile: mobile,
        project: project,
        project_id: projectid,
        source: source
    }

    $.ajax({
        type: "post",
        url: 'https://mls-crm.manishalifespaces.com/api/add_lead',
        data: lead,
        success: function (data, status, jqXHR) {
          window.location.href = 'https://api.whatsapp.com/send?phone='+whatsapp_number+'&text=Hello, I am Looking for '+project+'\, Please send me more details about this project.';
        },
        error: function (jqXHR, status) {
            console.log(status);
        }
    });

}

  return (
    <main style={pageStyles}>
      <Helmet>
        <title>WhatsApp</title>
      </Helmet>
      <Box sx={{ backgroundColor: '#128c7e', display: 'flex', justifyContent: 'center', paddingBlock: '20px' }}>
        <img src={WhatsApp} style={{ width: '50px' }} alt="Whatsapp" />
        <Typography variant="h6" sx={{ color: '#fff', mb: 0, ml: 1, paddingBlock: '10px' }}>
            Open WhatsApp
        </Typography>
      </Box>
      <Box id="whatsapp" sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ color: '#555', textAlign: 'center', mt: '50px', mb: 0}}>
            Chat on WhatsApp with
        </Typography>
        <Typography variant="h6" sx={{ color: '#555', textAlign: 'center', mb: 3}}>
            +{whatsapp_number}
        </Typography>
        <PhoneInput country={'in'} style={{ borderBottom: '1px solid #ccc !important', color: '#000!important' }} />
        <p style={{ color: 'red', margin: 'unset', display: 'none', paddingTop: '10px' }} class="whatsappErrorMsg"></p>
        <Box sx={{ textAlign: 'center', backgroundColor: '#edf8f4', padding: '18px', margin: '20px 0px', borderRadius: '5px' }}>
            <Typography variant="subtitle1" sx={{ color: '#555', textAlign: 'center' }}>
                Hello, I am Looking for {project}, Please send me more details about this project.
            </Typography>
        </Box>
        <Box sx={{ textAlign: 'center', pt: 6 }}>
            <Button onClick={() => fnSendMeDetail()} sx={{ backgroundColor: '#128c7e', textTransform: 'uppercase', '&:hover':{ backgroundColor: '#128c7e' } }} variant="contained">
                Continue to chat
            </Button>
        </Box>
      </Box>
    </main>
  )
}

export default WhatsappPage
